<template>
  <main class="page request-page" :class="{ 'request-create-page': !$route.params.id }">
    <div class="page-header">
      <div class="page-header__title">
        <a @click.prevent="$router.go(-1)" href="#" type="button" class="page__back">
          <ArrowLeftIcon />
          <span>Вернуться назад</span>
        </a>
        <h1 class="page__title">Новая заявка</h1>
      </div>
    </div>
    <div class="page__container">
      <span class="request-create-page__tip">
        Чтобы создать заявку, найдите и добавьте документ по которому осуществляется поставка
      </span>
      <div class="request-create-page__fake-input">
        <InputComponent
          title="Введите номер или название документа"
          @click="showSearchModal"
          ref="input"
          v-model="fake"
        />
      </div>
      <RequestCreatePageContracts :contracts="contracts" @change="searchModalCallback" />
      <div class="request-controls request-controls--first">
        <button
          type="button"
          class="btn btn--icon"
          :class="{
            'btn--outline': !loading,
            'btn--loading': loading,
          }"
          @click="createRequest"
        >
          <span>Далее</span>
          <LoaderIcon v-if="loading" class="loading-rotate" />
          <ChevronRightIcon v-else />
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";
import ContractsSearchInput from "./components/SearchInput";
import RequestCreatePageContracts from "./components/contracts";
import ChevronRightIcon from "@/components/svg/ChevronRightIcon";
import InputComponent from "@/components/inputs/InputComponent";
import LoaderIcon from "../../../components/svg/LoaderIcon.vue";

export default {
  name: "RequestCreateFirstStepPage",
  data() {
    return {
      fake: null,
      contracts: [],
      loading: false,
    };
  },
  methods: {
    createRequest() {
      if (!this.loading) {
        this.loading = true;
        this.$store
          .dispatch("CREATE_REQUEST", {
            context: this,
            variables: {
              contracts: this.contracts.map((c) => c.AccountID),
            },
          })
          .then(({ data }) => {
            if (data.RequestFirstStep) {
              this.$store.dispatch("GET_ME", {
                context: this,
              });
              this.$router.push({
                name: "RequestCreateContinue",
                params: { id: data.RequestFirstStep.id },
              });
            }
            this.loading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.$store.dispatch("notifyGQErrors", {
              context: this,
              errors: graphQLErrors,
            });
            this.loading = false;
          });
      }
    },
    showSearchModal() {
      document.body.classList.add("no-scroll"); // Чтобы поле расчитывало ширину без скролла
      this.$store.state._modals.push({
        component: ContractsSearchInput,
        options: {
          callback: this.searchModalCallback,
          rect: this.$refs.input.$refs.input.getBoundingClientRect(),
          selected: this.contracts,
        },
      });
    },
    searchModalCallback(e) {
      this.contracts = e;
    },
  },
  components: {
    LoaderIcon,
    ArrowLeftIcon,
    InputComponent,
    ChevronRightIcon,
    RequestCreatePageContracts,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/request"
.request-create-page__fake-input {
  margin-bottom 24px
}

.request-create-page {
  &__tip {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: var(--main_dark);
    margin-bottom 16px
  }

  .page__container {
    width 100%
    display grid
    grid-template-columns 1fr
  }
}
</style>
