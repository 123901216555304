<template>
  <div
    class="contracts-search"
    :style="`top:${options.rect.top}px;left:${options.rect.left}px;width:${options.rect.width}px`"
    :class="{
      'contracts-search--open': searchResults.length || empty,
    }"
  >
    <InputComponent
      title="Введите номер или название документа"
      @focus="focused = true"
      @blur="focused = false"
      v-model.trim="search"
      ref="input"
    >
      <LoaderIcon slot="action" class="loading-rotate" v-if="searchDebounceTimeout" />
    </InputComponent>
    <div class="contracts-search__drop" v-if="searchResults.length">
      <div class="contracts-search__drop-item" v-for="(item, index) in searchResults" :key="index">
        <div class="contracts-search__number">{{ item.ParentNumber }}</div>
        <div class="contracts-search__title contracts-search__title--mfix">
          {{ item.ExternalNumber }}
        </div>
        <div class="contracts-search__title">
          Ответственный:
          <b>
            {{ item.Curator }}
          </b>
        </div>
        <div class="contracts-search__button">
          <button
            class="btn-blue btn-blue--small"
            v-if="!selected.map((s) => s.AccountID).includes(item.AccountID)"
            @click="selectContract(item)"
          >
            Добавить
          </button>
          <button class="btn-gray btn-gray--small btn-gray--outline" v-else @click="removeContract(item)">
            Удалить
          </button>
        </div>
      </div>
    </div>
    <div class="contracts-search__drop" v-else-if="empty">
      <div class="contracts-search__not-found">
        <div class="contracts-search__title">
          <b>{{ loading ? "Загрузка..." : "Ничего не найдено" }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "@/components/inputs/InputComponent";
import LoaderIcon from "@/components/svg/LoaderIcon";

export default {
  name: "ContractsSearchInput",
  props: {
    options: Object,
  },
  data() {
    return {
      loading: false,
      focused: false,
      search: null,
      selected: this.options.selected,
      searchResults: [],
      searchDebounceTimeout: null,
    };
  },
  computed: {
    empty() {
      return this.search && this.search.length && !this.searchResults.length;
    },
  },
  watch: {
    search() {
      this.loading = true;
      clearTimeout(this.searchDebounceTimeout);
      this.searchDebounceTimeout = setTimeout(() => {
        this.searchContracts();
        this.searchDebounceTimeout = null;
      }, 500);
    },
  },
  mounted() {
    this.$refs.input.$refs.input.focus();
  },
  methods: {
    removeContract(item) {
      let index = this.selected.findIndex((s) => s.AccountID === item.AccountID);
      this.selected.splice(index, 1);
      this.options.callback(this.selected);
    },
    selectContract(item) {
      this.selected.push(item);
      this.options.callback(this.selected);
    },
    searchContracts() {
      this.$store
        .dispatch("PAGINATE_AGREEMENTS", {
          context: this,
          variables: {
            title: this.search,
          },
        })
        .then(({ data }) => {
          if (data.PaginateAgreements) {
            this.searchResults = data.PaginateAgreements.data;
          }
          this.loading = false;
        });
    },
  },
  components: { LoaderIcon, InputComponent },
};
</script>

<style lang="stylus">
.contracts-search {
  position absolute
  width 100%

  &--open {
    .input {
      border-bottom-left-radius 0
      border-bottom-right-radius 0
    }
  }

  &__drop {
    display flex
    flex-flow row wrap
    align-items center
    width 100%
    absolute left bottom
    transform translateY(100%)
    z-index 1
    padding-bottom 30px
  }

  &__not-found {
    background: var(--main_white);
    align-items center
    width 100%
    box-sizing border-box
    padding 16px
    border-bottom-left-radius var(--radius)
    border-bottom-right-radius var(--radius)

    &:not(:last-child) {
      border-bottom 1px solid var(--input_focused_border)
    }
  }

  &__drop-item {
    background: var(--main_white);
    display grid
    grid-gap 24px
    grid-template-columns 65px 1fr 1fr 120px
    align-items center
    width 100%
    box-sizing border-box
    padding 16px
    +below(768px) {
      grid-template-columns: 1fr;
      .btn-gray
      .btn-blue {
        width 100%
      }
    }

    &:not(:last-child) {
      border-bottom 1px solid var(--input_focused_border)
    }

    &:last-child {
      border-bottom-left-radius var(--radius)
      border-bottom-right-radius var(--radius)
    }
  }

  &__number {
    font-weight 500
    font-size: 1em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--main_dark_gray);
  }

  &__title {
    font-size: 0.8750em;
    line-height: 20px;
    letter-spacing: var(--letter_spacing);
    color: var(--main_dark);

    &--mfix {
      +below(768px) {
        font-weight bold
      }
    }

    b {
      font-weight bold
    }
  }
}
</style>
