<template>
  <label
    class="input"
    :class="{
      'input--default': notEmpty || focused,
      'input--error': error,
    }"
  >
    <span class="input__title">{{ title }}</span>
    <input
      :type="type"
      class="input__field"
      @focus="focus"
      ref="input"
      @blur="blur"
      @click="$emit('click')"
      :value="value"
      @input="$emit('input', $event.target.value)"
      :autofocus="autofocus"
      v-mask="mask"
    />
    <button type="button" class="input__action" v-if="$slots.action" @click.prevent="$emit('submit')">
      <slot name="action" />
    </button>
  </label>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    error: Boolean,
    value: [String, Number],
    mask: String,
    autofocus: Boolean,
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    notEmpty() {
      if (typeof this.value === "string") {
        return this.value && this.value.length;
      }
      return this.value !== null;
    },
  },
  methods: {
    focus() {
      if (!this.focused) {
        this.focused = true;
      }
      this.$emit("focus");
    },
    blur() {
      if (this.focused) {
        this.focused = false;
      }
      this.$emit("blur");
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/input"
</style>
